<template>
  <div class="navi">
    <div class="menus">
      <div class="menu-column">
        <span @click="showReportMenu">
          <img
            v-if="isReportMenuSelected"
            src="@/assets/icon-report-actived.svg"/>
          <img v-else
            src="@/assets/icon-report.svg"/>
        </span>
        <span @click="confirmDownload" class="mt-1">
          <img src="@/assets/icon-export.svg"/>
        </span>
      </div>
      <div class="menu-column">
        <span @click="showStudentsMenu">
          <img
            v-if="isStudentsMenuSelected"
            src="@/assets/icon-students-actived.svg"/>
            <img v-else
            src="@/assets/icon-students.svg"/>
        </span>
      </div>
    </div>
    <div class="title">
      学習状況レポート
    </div>
    <div class="filters">
      <div class="filter-line">
        <multi-select
          :options="gradeOptions"
          class="filter"
          label="学年"
          id="grade"
          :default="0"
          @changed="gradeChanged"/>
        <multi-select
          :options="classOptions"
          class="filter"
          label="組"
          id="class"
          :default="0"
          @changed="classChanged"/>
      </div>
      <div class="filter-line mt-2">
        <multi-select
          v-if="isStudentsInited"
          :options="filteredStudents"
          class="filter student-filter"
          label="生徒"
          id="student"
          :default="0"
          @changed="studentChanged"/>
        <report-calendar
          class="filter student-filter"
          @initialized="initialized"
          id="calendar"
          @changed="periodChanged"/>
      </div>
      <div class="filter-line mt-2">
        <button
          @click="updateCharts"
          class="btn-update">
          グラフ更新
        </button>
      </div>
    </div>
  </div>
  <div class="select-info">
    <div class="title">選択範囲</div>
    <div class="grade">
      <span class="subtitle">学年:</span>
      <div v-if="isAllGradeSelected"><span class="label">ALL</span></div>
      <div v-else-if="selectedGradeOptions.length == 0"><span class="label">未選択</span></div>
      <div v-else>
        <span class="label" v-for="grade in selectedGradeOptions" :key="grade.value">{{ grade.label }}</span>
      </div>
      <div v-if="showMoreGrade"><span>・・・他</span></div>
    </div>
    <div class="group">
      <span class="subtitle">組:</span>
      <div v-if="isAllClassSelected"><span class="label">ALL</span></div>
      <div v-else-if="selectedClassOptions.length == 0"><span class="label">未選択</span></div>
      <div v-else>
        <span class="label" v-for="cls in selectedClassOptions" :key="cls.value">{{ cls.label }}</span>
      </div>
      <div v-if="showMoreClass"><span>・・・他</span></div>
    </div>
    <div class="student">
      <span class="subtitle">生徒:</span>
      <div v-if="isAllStudentSelected"><span class="label">ALL</span></div>
      <div v-else-if="selectedStudents.length == 0"><span class="label">未選択</span></div>
      <div v-else>
        <span class="label" v-for="student in selectedStudentsOptions" :key="student.id">
          {{ student.name }} {{ student.label }}
        </span>
      </div>
      <div v-if="showMoreStudent"><span>・・・他</span></div>
    </div>
    <div class="range">
      <span class="subtitle">期間:</span>
      <span class="label">{{ selectedRange.start }}</span>
      〜
      <span class="label">{{ selectedRange.end }}</span>
    </div>
  </div>
  <div v-if="isReportMenuSelected" class="graph-container">
    <div class="graph-times">
      <div class="filter">
        <div class="title">期間ごとの受験回数</div>
        <div class="radio-box">
          <single-select
            :options="timeIntervalOptions"
            label="表示単位"
            class="filter"
            id="period"
            @changed="timeIntervalChanged"/>
        </div>
      </div>
      <div class="subtitle">Avg.{{ timeChartAvg }}</div>
      <div class="graph">
        <div class="empty" v-if="isTimeChartEmpty">
          データありませんでした
        </div>
        <div class="charts" v-else>
          <highcharts class="time-charts" :options="timeChartData" ref="chart"></highcharts>
        </div>
      </div>
    </div>
    <div class="summary">
      <div class="summary-box">
        <div class="icon"><img src="@/assets/icon-best.png"/></div>
        <div class="content">
          <div class="title lightblue">得意なLesson</div>
          <div class="text">{{ bestLesson }}</div>
        </div>
      </div>
      <div class="summary-box">
        <div class="icon"><img src="@/assets/icon-worst.png"/></div>
        <div class="content">
          <div class="title red">苦手なLesson</div>
          <div class="text">{{ worstLesson }}</div>
        </div>
      </div>
      <div class="summary-box">
        <div class="num-box">
          <div class="title red">今週のLesson受験回数</div>
          <div class="num-line">
            <div class="num">{{ weekly.lessonCurrent }}</div>
            <div class="pre">{{ weekly.lessonWow }}</div>
          </div>
        </div>
      </div>
      <div class="summary-box">
        <div class="num-box">
          <div class="title red">今週のTrial受験回数</div>
          <div class="num-line">
            <div class="num">{{ weekly.trialCurrent }}</div>
            <div class="pre">{{ weekly.trialWow }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isReportMenuSelected" class="class-achieve-container">
    <div class="titles">
      <span class="title">クラスごとの達成率</span>
      <span class="filter-line">
        <multi-select
          v-if="stepOptions.length > 1"
          :options="stepOptions"
          class="filter"
          label="Step"
          id="step"
          :default="0"
          @changed="stepChanged"/>
        <multi-select
          v-if="formOptions.length > 1"
          :options="formOptions"
          class="filter"
          label="Form"
          id="form"
          :default="0"
          @changed="formChanged"/>
        <multi-select
          :options="lessonOptions"
          class="filter lesson"
          label="Lesson"
          id="lesson"
          :default="0"
          @changed="lessonChanged"/>
      </span>
    </div>
    <div class="empty" v-if="isClassChartEmpty">
      データありませんでした
    </div>
    <div class="chart" v-else>
      <highcharts class="hc" :options="classAchieveChart" ref="chart"></highcharts>
    </div>
  </div>
  <AttentionStudents
    v-if="isStudentsMenuSelected"
    ref="attentionStudents"
    :start="selectedRange.start"
    :end="selectedRange.end"
    :userIds="selectedStudents"
    @refresh="refreshChild"
    />
  <div
    v-if="displayDialog"
    class="modal">
    <div class="dialog">
      <div class="center mt-1">
        現在のソート条件でデータエクスポートを行います
      </div>
      <div class="center mt-1">
        現在選択中の条件
      </div>
      <div>
        <table>
          <tr>
            <td class="title">学年:</td>
            <td class="labels">
              <div v-if="isAllGradeSelected"><span class="label">ALL</span></div>
              <div v-else-if="selectedGradeOptions.length == 0"><span class="label">未選択</span></div>
              <div v-else>
                <span class="label" v-for="grade in selectedGradeOptions" :key="grade.value">{{ grade.label }}</span>
              </div>
            </td>
            <td class="more">
              <span v-if="showMoreGrade">・・・他</span>
            </td>
          </tr>
          <tr>
            <td class="title">組:</td>
            <td class="labels">
              <div v-if="isAllClassSelected"><span class="label">ALL</span></div>
              <div v-else-if="selectedClassOptions.length == 0"><span class="label">未選択</span></div>
              <div v-else>
                <span class="label" v-for="cls in selectedClassOptions" :key="cls.value">{{ cls.label }}</span>
              </div>
            </td>
            <td class="more">
              <span v-if="showMoreClass">・・・他</span>
            </td>
          </tr>
          <tr>
            <td class="title">生徒:</td>
            <td class="labels">
              <div v-if="isAllStudentSelected"><span class="label">ALL</span></div>
              <div v-else-if="selectedStudentsOptions.length == 0"><span class="label">未選択</span></div>
              <div v-else>
                <span class="label" v-for="student in selectedStudentsOptions" :key="student.id">
                  {{ student.name }} {{ student.label }}
                </span>
              </div>
            </td>
            <td class="more">
              <span v-if="showMoreStudent">・・・他</span>
            </td>
          </tr>
          <tr>
            <td class="title">期間:</td>
            <td class="labels">
              <span class="label">{{ selectedRange.start }}</span>
              〜
              <span class="label">{{ selectedRange.end }}</span>
            </td>
            <td></td>
          </tr>
        </table>
      </div>
      <div class="btn-row">
        <button
          @click="closeDialog"
          class="btn-bg center">
          戻る
        </button>
        <button
          @click="download"
          class="btn-bg center">
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from '@/components/atom/MultiSelect.vue'
import SingleSelect from '@/components/atom/SingleSelect.vue'
import ReportCalendar from '@/components/molecule/ReportCalendar.vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import AttentionStudents from './AttentionStudents.vue'

exportingInit(Highcharts)

export default {
  name: 'Report',
  components: {
    MultiSelect, ReportCalendar, SingleSelect, AttentionStudents,
  },
  data() {
    return {
      gradeOptions: [
        { label: '1年', value: '1' },
        { label: '2年', value: '2' },
        { label: '3年', value: '3' },
        { label: '4年', value: '4' },
        { label: '5年', value: '5' },
        { label: '6年', value: '6' },
        { label: 'ALL', value: '0' },
      ],
      classOptions: [
        { label: '1(A)組', value: '1' },
        { label: '2(B)組', value: '2' },
        { label: '3(C)組', value: '3' },
        { label: '4(D)組', value: '4' },
        { label: '5(E)組', value: '5' },
        { label: '6(F)組', value: '6' },
        { label: '7(G)組', value: '7' },
        { label: '8(H)組', value: '8' },
        { label: 'ALL', value: '0' },
      ],
      allStudents:[],
      timeIntervalOptions: [
        { label: '時', value: '1' },
        { label: '日', value: '2' },
        { label: '月', value: '3' },
        { label: '年', value: '4' },
      ],
      stepOptions: [],
      formOptions: [],
      lessonOptions: [
        { label: 'Lesson 1', value: 'lesson-1' },
        { label: 'Lesson 2', value: 'lesson-2' },
        { label: 'Lesson 3', value: 'lesson-3' },
        { label: 'Lesson 4', value: 'lesson-4' },
        { label: 'Lesson 5', value: 'lesson-5' },
        { label: 'Lesson 6', value: 'lesson-6' },
        { label: 'Lesson 7', value: 'lesson-7' },
        { label: 'Lesson 8', value: 'lesson-8' },
        { label: 'Lesson 9', value: 'lesson-9' },
        { label: 'Lesson 10', value: 'lesson-10' },
        { label: 'Lesson 11', value: 'lesson-11' },
        { label: 'Lesson 12', value: 'lesson-12' },
        { label: 'Lesson 13', value: 'lesson-13' },
        { label: 'Lesson 14', value: 'lesson-14' },
        { label: 'Lesson 15', value: 'lesson-15' },
        { label: 'Lesson 16', value: 'lesson-16' },
        { label: 'Trial Reading', value: 'trial_reading' },
        { label: 'Trial Listening', value: 'trial_listening' },
        { label: 'Trial Examination', value: 'trial' },
        { label: 'ALL', value: '0' },
      ],
      // 学年、組と連動してfilteredされた生徒
      filteredStudents:[],
      selectedGrades:[0],
      selectedClasses:[0],
      selectedStudents:[0],
      selectedSteps: [0],
      selectedForms: [0],
      selectedLessons: [0],
      selectedRange: {},
      selectedInterval: 2,
      displayDialog: false,
      bestLesson: '--',
      worstLesson: '--',
      timeChartAvg: 0,
      timeChartData: {
        credits: {
          enabled: false // Highcharts.comを削除
        },
        chart: {
          type: 'column',
        },
        title: {
          text: ''
        },
        plotOptions: {
          column: {
            color: '#a3a1fb',
            borderRadius: 5
          }
        },
        legend: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
        yAxis: {
          title: {
            text: null
          },
          gridLineColor: '#eaf0f4'
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
        },
        series: [
          {
            name: '受験回数',
            data: [6, 2, 3, 1, 4, 6, 5],
          },
        ],
      },
      weekly: {
        lessonCurrent: 0,
        trialCurrent: 0,
        lessonWow: '--',
        trialWow: '--',
      },
      classAchieveChart: {
        credits: {
          enabled: false // Highcharts.comを削除
        },
        chart: {
          type: 'bar',
          height: null,
        },
        title: {
          text: null,
        },
        plotOptions: {
          bar: {
            color: '#3b86ff',
            borderRadius: 3,
            pointWidth: 15,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return (this.y * 100).toFixed(2) + "%"
              }
            }
          }
        },
        legend: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
        xAxis: {
          categories: [],
          title: {
            text: null
          },
        },
        yAxis: {
          gridLineColor: 'white',
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          max: 1,
        },
        series: [{
            name: '',
            type: 'bar',
            data: []
        }],
        tooltip: {
          formatter: function() {
            return "<b>" + this.x + "</b><br>" + (this.y * 100).toFixed(2) + "%"
          }
        },
      },
      isTimeChartEmpty: true,
      isClassChartEmpty: true,
      isStudentsInited: false,
      currentMenu: 'report',
    }
  },
  computed: {
    isAllGradeSelected() {
      if (this.selectedGrades.includes('0')) return true
      return false
    },
    selectedGradeOptions() {
      return this.gradeOptions.filter(g => this.selectedGrades.includes(String(g.value))).slice(0, 3)
    },
    showMoreGrade() {
      if (!this.isAllGradeSelected && this.selectedGrades.length > 3) return true
      return false
    },
    isAllClassSelected() {
      if (this.selectedClasses.includes('0')) return true
      return false
    },
    selectedClassOptions() {
      return this.classOptions.filter(g => this.selectedClasses.includes(String(g.value))).slice(0, 2)
    },
    showMoreClass() {
      if (!this.isAllClassSelected && this.selectedClasses.length > 2) return true
      return false
    },
    isAllStudentSelected() {
      if (this.selectedStudents.find(e => e == 0) != undefined) return true
      return false
    },
    selectedStudentsOptions() {
      return this.filteredStudents.filter(g => this.selectedStudents.includes(g.value)).slice(0, 1)
    },
    showMoreStudent() {
      if (!this.isAllStudentSelected && this.selectedStudents.length > 1) return true
      return false
    },
    isReportMenuSelected() {
      return this.currentMenu == 'report'
    },
    isStudentsMenuSelected() {
      return this.currentMenu == 'students'
    },
  },
  mounted() {
  },
  methods: {
    async initialized(range) {
      // 先にstep情報取得する必要がある
      this.getStepData()
      this.selectedRange = {
        start: this.dateFormat(range.start),
        end: this.dateFormat(range.end),
      }

      // student情報取得
      this.allStudents = (await this.$api.get('/students/all')).data
      this.filteredStudents = this.allStudents.map(s => {
        return {
          name: s.label,
          value: s.id,
          label: s.name,
        }
      })
      this.filteredStudents.unshift({
        name: "",
        value: 0,
        label: "ALL"
      })
      this.selectedStudents = this.filteredStudents.map(s => s.value)
      // 学生のdefault=allは特別対応が必要
      this.isStudentsInited = true

      // グラフ更新
      this.getTimeChart(2)
      this.getExtremeData()
      this.getWeeklyData()
      this.getClassChart()
    },
    gradeChanged(newVal) {
      this.selectedGrades = newVal
      this.changeSelectedStudents()
    },
    classChanged(newVal) {
      this.selectedClasses = newVal
      this.changeSelectedStudents()
    },
    stepChanged(newVal) {
      this.selectedSteps = newVal
    },
    formChanged(newVal) {
      this.selectedForms = newVal
    },
    initSelectAllStudents() {

    },
    lessonChanged(newVal) {
      this.selectedLessons = newVal
    },
    timeIntervalChanged(newVal) {
      this.selectedInterval = newVal
      this.getTimeChart(this.selectedInterval)
    },
    studentChanged(newVal) {
      this.selectedStudents = newVal
    },
    changeSelectedStudents() {
      const filteredClasses = this.allStudents.filter(s => {
        if (this.selectedClasses.includes('0')) return true
        return this.selectedClasses.includes(String(s.class))
      })
      const filteredGrades = filteredClasses.filter(s => {
        if (this.selectedGrades.includes('0')) return true
        return this.selectedGrades.includes(String(s.grade))
      })
      this.filteredStudents = filteredGrades.map(s => {
        return {
          name: s.label,
          value: s.id,
          label: s.name,
        }
      })
      this.filteredStudents.unshift({
        name: "",
        value: 0,
        label: "ALL"
      })
    },
    periodChanged(newVal) {
      console.log('changed:' + newVal.start)
      this.selectedRange = {
        start: this.dateFormat(newVal.start),
        end: this.dateFormat(newVal.end),
      }
    },
    confirmDownload() {
      this.displayDialog = true
    },
    closeDialog() {
      this.displayDialog = false
    },
    download() {
      this.$api.post(
        `/testHistory/download`,
        {
          students: this.selectedStudents,
          from: this.selectedRange.start,
          to: this.selectedRange.end,
        },
        { responseType : 'blob' }
      ).then(res => {
        const link = document.createElement('a')
        const dateStr = (new Date()).toISOString().slice(0, 10)
        link.href = URL.createObjectURL(res.data)
        link.download = `${dateStr}_report.xlsx`
        link.click()
      })
    },
    dateFormat(date) {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}-${month}-${day}`
    },
    getTimeChart(interval) {
      this.$api.post('/report/times/range', {
        start: this.selectedRange.start,
        end: this.selectedRange.end,
        interval: interval,
        userIds: this.selectedStudents
      }).then(res => {
        const xAxis = res.data.map(i => i.interval)
        const values = res.data.map(i => i.value)
        this.timeChartData.xAxis.categories = xAxis
        this.timeChartData.series[0].data = values
        const sum = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        this.timeChartAvg = parseInt(values.length > 0 ? sum / values.length : 0)
        this.isTimeChartEmpty = sum == 0 ? true : false
      })
    },
    updateCharts() {
      this.getTimeChart(this.selectedInterval)
      this.getExtremeData()
      this.getWeeklyData()
      this.getClassChart()
      this.$refs.attentionStudents.refresh()
    },
    getExtremeData() {
      this.$api.post('/report/lesson/extreme', {
        start: this.selectedRange.start,
        end: this.selectedRange.end,
        userIds: this.selectedStudents
      }).then(res => {
        this.bestLesson = res.data.bestLesson
        this.worstLesson = res.data.worstLesson
      })
    },
    getWeeklyData() {
      this.$api.post('/report/times/weekly', {userIds: this.selectedStudents}).then(res => {
        this.weekly = {
          lessonCurrent: res.data.lessonCurrent,
          trialCurrent: res.data.trialCurrent,
          lessonWow: res.data.lessonWowRatio,
          trialWow: res.data.trialWowRatio,
        }
      })
    },
    async getStepData() {
      const res = await this.$api.get('/steps')

      this.stepOptions = res.data.steps.map(s => {
        return {
          label: s.stepName,
          value: s.stepId,
        }
      })
      this.stepOptions.push({label: 'ALL', value: '0'})
      this.formOptions = res.data.steps[0].forms.map(f => {
        return {
          label: f.formName,
          value: f.formId,
        }
      })
      this.formOptions.push({label: 'ALL', value: '0'})
      // step情報を取得後、default設定を更新する
      this.selectedSteps = this.stepOptions.map(s => s.value)
      this.selectedForms = this.formOptions.map(f => f.value)
      console.log('selectedForms:' + this.selectedForms)
      this.selectedLessons = this.lessonOptions.map(l => l.value)
    },
    getClassChart() {
      if (this.selectedGrades.length < 1
        || this.selectedClasses.length < 1
        || this.selectedSteps.length < 1
        || this.selectedForms.length < 1
        || this.selectedLessons.length < 1) {
          this.isClassChartEmpty = true
          return
      }
      this.$api.post('/report/class/achievement', {
        grades: this.selectedGrades.filter(g => g != '0' ),
        classes: this.selectedClasses.filter(c => c != '0'),
        steps: this.selectedSteps.filter(c => c != '0'),
        forms: this.selectedForms.filter(c => c != '0'),
        lessons: this.selectedLessons.filter(c => c != '0'),
        userIds: this.selectedStudents,
        endDate: this.selectedRange.end
      }).then(res => {
        const xAxis = res.data.classes.map(c => c.name)
        const values = res.data.classes.map(c => c.achieveRatio)
        // グラフの高さを調整する
        this.classAchieveChart.chart.height = 20 * xAxis.length
        this.classAchieveChart.xAxis.categories = xAxis
        this.classAchieveChart.series[0].data = values
        this.isClassChartEmpty = false
      })
    },
    showReportMenu() {
      this.currentMenu = 'report'
    },
    showStudentsMenu() {
      this.currentMenu = 'students'
    }
  },
}
</script>

<style lang="scss">
.dropdown, .checkbox {
  z-index: 1;
}
.navi {
  display: flex;
  padding-top: 20px;
  height: 165px;
  box-shadow: 0px 3px 6px #00000029;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 10;
  margin-top: -20px;

  .menus {
    flex: 3;
    display: flex;
    justify-content: center;
    span {
      width: 150px;
      display: inline-block;
      cursor: pointer;
      img {
        width: 100%;
      }
      img:hover {
        width: 105%;
      }
    }
    .menu-column {
      width: 168px;
    }
  }
  .title {
    flex: 3;
    font-size: 28px;
    text-align: center;
  }
  .filters {
    flex: 3;
    .filter-line {
      display: flex;
      justify-content: space-evenly;
      .btn-update {
        background: linear-gradient(to left, #28529D, #0E1D3A);
        color: white;
        font-weight: bold;
        padding: 5px 25px;
        border-radius: 19px;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        border: none;
      }
    }
  }
  .student-filter {
    label {
      font-size: 12px;
    }
  }
}
.select-info {
  display: flex;
  margin-top: 15px;
  align-items: center;
  padding-top: 180px;
  .label {
    border: 1px solid #1F4382;
    border-radius: 12px;
    padding: 2px 8px;
    margin: 3px 3px;
    font-weight: 600;
    display: inline-block;
  }
  .title {
    font-size: 20px;
    flex: 1;
    flex-basis: 40px;
  }
  .subtitle {
    word-break: keep-all;
  }
  .grade, .group, .student, .range {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
  }
  .grade {
    flex: 3;
  }
  .group {
    flex: 3;
  }
  .student {
    flex: 3;
    flex-basis: 30px;
  }
  .range {
    flex: 3;
    flex-basis: 10px;
  }
}
.graph-container {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-evenly;
  margin-top: 40px;

  .graph-times {
    flex: 5;
    padding: 10px 30px 10px 10px;
    .time-charts {
      height: 350px;
    }
    .subtitle {
      text-align: left;
      font-weight: bold;
      color: #a3a1fb;
    }
    .filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 16px;
      }
    }
  }
  .summary {
    flex: 2;
    .lightblue {
      color: #56D9FE;
    }
    .red {
      color: #CD1111;
    }
    .summary-box {
      display: flex;
      box-shadow: 0px 2px 6px #0000003D;
      justify-content: space-evenly;
      height: 80px;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
      .icon {
        flex: 1;
      }
      img {
        width: 50px;
        height: 50px;
      }
      .content {
        flex: 2;
        text-align: left;
      }
      .title {
        font-size: 16px;
      }
      .num-box {
        text-align: left;
        width: 80%;
        .num-line {
          display: flex;
          align-items: center;
          .num {
            font-weight: bold;
            font-size: 20px;
          }
          .pre {
            font-weight: bold;
            padding-left: 10px;
          }
        }
      }
    }
  }
}
.class-achieve-container {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
  padding-bottom: 20px;
  box-shadow: 0px 2px 6px #00000070;
  .titles {
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 16px;
    }
    .filter-line {
      display: flex;
      .filter {
        margin: 0 15px;
      }
      .lesson {
        .label {
          width: 155px;
        }
        .checkbox {
          width: 175px;
          margin-left: -96px;
        }
      }
    }
  }
  .chart {
    margin: 0 20px 0 10px;
  }
}
.empty {
  margin-top: 100px;
  margin-bottom: 100px;
}
.modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  font-size: 26px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  .dialog {
    width: 800px;
    min-height: 70%;
    min-width: 800px;
    max-width: 1200px;
    background: white;
    margin: auto;
    box-shadow: 0px 3px 6px #00000029;
    padding-top: 20px;
    z-index: 21;
  }
  .btn-row {
    display: flex;
    justify-content: space-evenly;
  }
  table {
     width: 90%;
     margin: 10px auto 20px auto;
     tr {
      height: 70px;
     }
    .title {
      width: 12%;
      text-align: right;
    }
    .labels {
      width: 50%;
      .label {
        border: 1px solid #1F4382;
        border-radius: 25px;
        padding: 5px 18px;
        margin: 3px 8px;
        font-weight: 600;
        display: inline-block;
      }
    }
    .more {
      width: 15%;
      text-align: left;
    }
  }
}
</style>

